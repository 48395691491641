import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { useCommand, useRegisterCommands } from '@meterup/command';
import {
  DesktopSidebar,
  MobileSidebar,
  MobileSidebarLocationControlContainer,
  MobileSidebarToggle,
  MobileSidebarToggleContainer,
  SidebarDivider,
  SidebarGroup,
  SidebarNavItem,
} from '@meterup/metric';
import React, { useMemo, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';

import { LocationControl } from '../../../components/Navigation/LocationControl';
import { MobileSidebarModal } from '../../../components/Navigation/MobileSidebarModal';
import { ReactRouterLink } from '../../../components/ReactRouterLink';
import { SidebarAndMainLayout } from '../../../components/SidebarAndMainLayout';
import { paths } from '../../../constants';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useIsActiveMatcher } from '../../../hooks/useIsActive';
import { useLogoutHandler } from '../../../hooks/useLogoutHandler';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import {
  useCurrentController,
  useCurrentControllerVersion,
} from '../../../providers/CurrentControllerProvider';
import { styled } from '../../../stitches';
import { makeLink } from '../../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  name: 'SidebarLayout',
  layout: 'AppLayout',
});

const LogoutButton = styled('div', { marginTop: 'auto', width: '100%', padding: '$12' });

export const SidebarEntries = ({ onNavItemClick }: { onNavItemClick?: () => void }) => {
  const isActiveTest = useIsActiveMatcher();
  const controllerVersion = useCurrentControllerVersion();
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const flags = useFeatureFlags();

  const { state } = useCommand();

  const navigate = useNavigate();

  const nodes = useMemo(() => {
    const n = [];

    if (flags['metrics-ui']) {
      n.push(
        state.nodeFactory.action({
          id: 'nav-overview',
          group: 'Navigation',
          display: 'Overview',
          label: 'Overview',
          icon: 'home',
          onSelect() {
            navigate(makeLink(paths.pages.OverviewPage, { companyName, controllerName }));
          },
        }),
      );
    }

    n.push(
      ...[
        state.nodeFactory.action({
          id: 'nav-clients',
          group: 'Navigation',
          display: 'Clients',
          label: 'Clients',
          icon: 'client',
          onSelect() {
            navigate(
              makeLink(paths.pages.ClientsListPage, {
                companyName,
                controllerName,
              }),
            );
          },
        }),
        state.nodeFactory.action({
          id: 'nav-access-points',
          group: 'Navigation',
          display: 'Access points',
          label: 'Access points',
          icon: 'accessPoint',
          onSelect() {
            navigate(
              makeLink(paths.pages.AccessPointListPage, {
                companyName,
                controllerName,
              }),
            );
          },
        }),
      ],
    );

    if (controllerVersion.isCOS && flags['cos-config']) {
      n.push(
        ...[
          state.nodeFactory.action({
            id: 'nav-ssids',
            group: 'Navigation',
            display: 'SSIDs',
            label: 'SSIDs',
            icon: 'wifi',
            onSelect() {
              navigate(
                makeLink(paths.pages.SSIDListPage, {
                  companyName,
                  controllerName,
                }),
              );
            },
          }),
          state.nodeFactory.action({
            id: 'nav-isps',
            group: 'Navigation',
            display: 'ISPs',
            label: 'ISPs',
            icon: 'internet',
            onSelect() {
              navigate(
                makeLink(paths.pages.ISPListPage, {
                  companyName,
                  controllerName,
                }),
              );
            },
          }),
        ],
      );
    } else {
      n.push(
        state.nodeFactory.action({
          id: 'nav-internet-and-wireless',
          group: 'Navigation',
          display: 'Internet & wireless',
          label: 'Internet & wireless',
          icon: 'internet',
          onSelect() {
            navigate(
              makeLink(paths.pages.InternetAndWirelessPage, {
                companyName,
                controllerName,
              }),
            );
          },
        }),
      );
    }

    n.push(
      state.nodeFactory.action({
        id: 'nav-event-logs',
        group: 'Navigation',
        display: 'Event logs',
        label: 'Event logs',
        icon: 'log',
        onSelect() {
          navigate(
            makeLink(paths.pages.EventsListPage, {
              companyName,
              controllerName,
            }),
          );
        },
      }),
    );

    return n;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerVersion.isCOS, flags, state.nodeFactory]);

  useRegisterCommands(nodes, nodes);

  return (
    <>
      {flags['metrics-ui'] && (
        <>
          <SidebarGroup>
            <SidebarNavItem
              as={ReactRouterLink}
              to={makeLink(paths.pages.OverviewPage, { companyName, controllerName })}
              label="Overview"
              isSelected={isActiveTest({ path: paths.pages.OverviewPage, end: true })}
              icon="home"
              onClick={onNavItemClick}
            />
          </SidebarGroup>
          <SidebarDivider />
        </>
      )}
      <SidebarGroup>
        <SidebarNavItem
          as={ReactRouterLink}
          to={makeLink(paths.pages.ClientsListPage, {
            companyName,
            controllerName,
          })}
          label="Clients"
          isSelected={
            isActiveTest(paths.pages.ClientsListPage) ||
            isActiveTest({ path: paths.pages.ClientsListPage, end: false })
          }
          icon="client"
          onClick={onNavItemClick}
        />
        <SidebarNavItem
          as={ReactRouterLink}
          to={makeLink(paths.pages.AccessPointListPage, {
            companyName,
            controllerName,
          })}
          label="Access points"
          isSelected={isActiveTest({ path: paths.pages.AccessPointListPage, end: false })}
          icon="device"
          onClick={onNavItemClick}
        />
        {controllerVersion.isCOS && flags['tunnel-ui'] && (
          <SidebarNavItem
            as={ReactRouterLink}
            to={makeLink(paths.pages.VPNListPage, { companyName, controllerName })}
            label="VPN"
            isSelected={isActiveTest({ path: paths.pages.VPNListPage, end: false })}
            icon="secure"
            onClick={onNavItemClick}
          />
        )}
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup>
        {controllerVersion.isCOS && flags['cos-config'] ? (
          <>
            <SidebarNavItem
              as={ReactRouterLink}
              to={makeLink(paths.pages.SSIDListPage, {
                companyName,
                controllerName,
              })}
              label="SSIDs"
              isSelected={isActiveTest(paths.pages.SSIDListPage)}
              icon="ssid"
              onClick={onNavItemClick}
            />
            <SidebarNavItem
              as={ReactRouterLink}
              to={makeLink(paths.pages.ISPListPage, {
                companyName,
                controllerName,
              })}
              label="ISPs"
              isSelected={isActiveTest(paths.pages.ISPListPage)}
              icon="internet"
              onClick={onNavItemClick}
            />
            {flags['content-filtering-ui'] && (
              <SidebarNavItem
                as={ReactRouterLink}
                to={makeLink(paths.pages.ContentFilterListPage, {
                  companyName,
                  controllerName,
                })}
                label="Content filtering"
                isSelected={isActiveTest(paths.pages.ContentFilterListPage)}
                icon="contentFilter"
                onClick={onNavItemClick}
              />
            )}
          </>
        ) : (
          <SidebarNavItem
            as={ReactRouterLink}
            to={makeLink(paths.pages.InternetAndWirelessPage, {
              companyName,
              controllerName,
            })}
            label="Internet & wireless"
            isSelected={isActiveTest(paths.pages.InternetAndWirelessPage)}
            icon="internet"
            onClick={onNavItemClick}
          />
        )}
      </SidebarGroup>
      {flags['controller-events'] && (
        <>
          <SidebarDivider />
          <SidebarGroup>
            <SidebarNavItem
              as={ReactRouterLink}
              to={makeLink(paths.pages.EventsListPage, {
                companyName,
                controllerName,
              })}
              label="Event logs"
              isSelected={isActiveTest('/events')}
              icon="log"
              onClick={onNavItemClick}
            />
          </SidebarGroup>
        </>
      )}
    </>
  );
};
const NetworkSidebarDesktop = () => (
  <DesktopSidebar>
    <SidebarEntries />
    <LogoutButton>
      <SidebarNavItem as="button" label="Log out" onClick={useLogoutHandler()} />
    </LogoutButton>
  </DesktopSidebar>
);

const NetworkSidebarMobile = ({ onClose }: { onClose: () => void }) => (
  <MobileSidebarModal onClose={onClose}>
    <MobileSidebar>
      <MobileSidebarToggleContainer>
        <MobileSidebarToggle icon="cross" label="Navigation" onClick={onClose} />
      </MobileSidebarToggleContainer>
      <SidebarEntries onNavItemClick={onClose} />
      <SidebarGroup>
        <SidebarNavItem as="button" label="Log out" onClick={useLogoutHandler()} />
      </SidebarGroup>
      <MobileSidebarLocationControlContainer>
        <LocationControl onClose={onClose} />
      </MobileSidebarLocationControlContainer>
    </MobileSidebar>
  </MobileSidebarModal>
);

export default function SidebarLayout() {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  const openMobileMenu = () => setIsMobileSidebarOpen(true);
  const closeMobileMenu = () => setIsMobileSidebarOpen(false);

  return (
    <SidebarAndMainLayout
      mobileSidebarToggle={
        <MobileSidebarToggle icon="menu" label="Meter" onClick={openMobileMenu} />
      }
      mobileSidebar={
        isMobileSidebarOpen ? <NetworkSidebarMobile onClose={closeMobileMenu} /> : null
      }
      desktopSidebar={<NetworkSidebarDesktop />}
      main={<Outlet />}
    />
  );
}
