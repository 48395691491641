import { bytesPerSecond, formatDataRateBits } from '@meterup/common';
import {
  Badge,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTriggerButton,
  Skeleton,
} from '@meterup/metric';
import * as d3 from 'd3';
import React, { Suspense } from 'react';
import { useIsFetching, useQuery, useQueryClient } from 'react-query';

import { fetchClients } from '../../../api/api';
import {
  ActiveClientCount,
  ISPQuality,
  ISPThroughputRx,
  ISPThroughputTx,
} from '../../../api/metrics';
import { Box } from '../../../components/Box';
import { ClientsList } from '../../../components/ClientsList';
import { Page, PageControls, PageHeader, PageTitle } from '../../../components/Page/Page';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useCurrentController } from '../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import MetricGraph from './overview/MetricGraph';
import StatusBar from './overview/StatusBar';

const dataRateValueFormatter = (value: number) => formatDataRateBits(value, bytesPerSecond);
const percentValueFormatter = d3.format('.2~%');
type WanType = 'wan0' | 'wan1';

const OverviewMetricsPage = () => {
  const [currentWANOrUndefined, setCurrentWAN] = useSearchParamsState<WanType>('isp', 'wan0');
  const currentWAN = currentWANOrUndefined ?? 'wan0';

  const controllerName = useCurrentController();
  const queryClient = useQueryClient();
  const loading = useIsFetching({ queryKey: ['metrics'] }) > 0;

  const refreshQueries = () => {
    queryClient.refetchQueries({ queryKey: ['metrics'] });
  };
  const wanToLabel = (wan: WanType) => (wan === 'wan0' ? 'Primary' : 'Backup');

  return (
    <Page>
      <PageHeader>
        <PageTitle>Overview</PageTitle>
        <PageControls>
          <DropdownMenu>
            <DropdownMenuTriggerButton variant="tertiary" arrangement="leading-icon" icon="wifi">
              {wanToLabel(currentWAN)}
            </DropdownMenuTriggerButton>

            <DropdownMenuContent>
              <DropdownMenuRadioGroup
                value={currentWAN}
                onValueChange={(val) => setCurrentWAN(val as WanType)}
              >
                <DropdownMenuRadioItem value="wan0">{wanToLabel('wan0')}</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="wan1">{wanToLabel('wan1')}</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>

          <Button
            variant="tertiary"
            size="medium"
            icon="clock"
            arrangement="leading-icon"
            disabled
            loading={loading}
          >
            Last 24 hours
          </Button>

          <Button
            variant="tertiary"
            size="medium"
            arrangement="hidden-label"
            icon="arrowRotate"
            onClick={refreshQueries}
          >
            Refresh
          </Button>
        </PageControls>
      </PageHeader>

      <StatusBar wan={currentWAN} />

      <Suspense
        fallback={
          <Box css={{ padding: '$12', width: '100%' }}>
            <Skeleton width="100%" height="240px" radius={8} />
          </Box>
        }
      >
        <MetricGraph
          title="ISP quality"
          tooltipBody="The percentage of successful pings to common web servers over each ISP connection."
          metric={ISPQuality}
          valueFormatter={percentValueFormatter}
          yTicks={[0, 1]}
          yDomain={[0, 2]}
          controllerName={controllerName}
          wan={currentWAN}
        />
        <MetricGraph
          metric={ISPThroughputRx}
          title="ISP throughput (Download)"
          tooltipBody="The number of bytes received from the public internet."
          valueFormatter={dataRateValueFormatter}
          controllerName={controllerName}
          wan={currentWAN}
        />
        <MetricGraph
          metric={ISPThroughputTx}
          title="ISP throughput (Upload)"
          tooltipBody="The number of bytes sent to the public internet."
          valueFormatter={dataRateValueFormatter}
          controllerName={controllerName}
          wan={currentWAN}
        />
        <MetricGraph
          metric={ActiveClientCount}
          title="Active clients"
          titleBadge={
            <Badge
              arrangement="leading-icon"
              ends="pill"
              icon="wifi"
              size="small"
              variant="neutral"
            >
              All Networks
            </Badge>
          }
          tooltipBody="The number of clients sending traffic on the network. Includes wired and wireless clients and excludes Meter devices."
          valueFormatter={(d) => d.toFixed(0)}
          controllerName={controllerName}
          wan={currentWAN}
        />
      </Suspense>
    </Page>
  );
};

// TODO(apt, 2023-02-14): Remove this when we launch metrics fully.
const OverviewClientsPage = () => {
  const controller = useCurrentController();
  const flags = useFeatureFlags();
  const clients =
    useQuery(['clients', controller], () => fetchClients(controller), { suspense: true }).data ??
    [];
  return (
    <Page>
      <ClientsList clients={clients} showTabSwitcher={flags['client-wired-wireless-filter-ui']} />
    </Page>
  );
};

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName',
});

export default function OverviewPage() {
  const flags = useFeatureFlags();
  return flags['metrics-ui'] ? <OverviewMetricsPage /> : <OverviewClientsPage />;
}
