import React from 'react';
import { useQuery } from 'react-query';

import type { MetricsAPIEndpoint } from '../../../../api/metrics';
import MetricsAPI from '../../../../api/metrics';
import { TimeSeriesChart } from '../../../../components/TimeSeriesChart';

interface MetricGraphProps {
  metric: MetricsAPIEndpoint;
  controllerName: string;
  wan: string;
  tooltipBody: string;
  title: string;
  titleBadge?: React.ReactNode;
  valueFormatter: (value: number) => string;
  yTicks?: number[];
  yDomain?: number[];
}
export default function MetricGraph({
  metric,
  controllerName,
  wan,
  title,
  tooltipBody,
  titleBadge,
  valueFormatter,
  yTicks,
  yDomain,
}: MetricGraphProps) {
  const { data, isError } = useQuery(
    ['metrics', metric.series_id, controllerName, wan],
    () => MetricsAPI.fetchMetric(metric, { controllerName, wan }),
    {
      suspense: true,
    },
  );

  return (
    <TimeSeriesChart
      title={title}
      tooltipBody={tooltipBody}
      titleBadge={titleBadge}
      series={data!}
      valueFormatter={valueFormatter}
      yTicks={yTicks}
      yDomain={yDomain}
      isError={isError}
    />
  );
}
