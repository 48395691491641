import type { BandVariant, IconName } from '@meterup/metric';
import {
  expectDefinedOrThrow,
  MetricsLoadError,
  ResourceNotFoundError,
  shadows,
  styled,
} from '@meterup/common';
import { Badge, HardwareIcon, HStack, NetworkIcon, Small, space } from '@meterup/metric';
import * as d3 from 'd3';
import { groupBy } from 'lodash';
import React, { Suspense } from 'react';
import { useQuery } from 'react-query';

import { fetchDevicesForController } from '../../../../api/api';
import MetricsAPI, { ISPQuality } from '../../../../api/metrics';
import { BandIconAndBadge } from '../../../../components/BandIconAndBadge';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';

const Grid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
});

const GridItem = styled('div', {
  vStack: 8,
  padding: '$20',
  boxShadow: shadows.fenceAllLight,
  textAlign: 'center',
  height: '100%',
});

const LoadingGrid = () => (
  <Grid>
    <GridItem>
      <BandIconAndBadge
        variant="neutral"
        size="medium"
        badge=""
        icon={<HardwareIcon icon="controller" size="medium" />}
      />
      <Small>Controller</Small>
    </GridItem>
    <GridItem>
      <BandIconAndBadge
        variant="neutral"
        size="medium"
        badge=""
        icon={<HardwareIcon icon="ap" size="medium" />}
      />
      <Small>Access points</Small>
    </GridItem>
    <GridItem>
      <BandIconAndBadge variant="neutral" size="medium" badge="" icon={<NetworkIcon />} />
      <Small>ISP quality</Small>
    </GridItem>
  </Grid>
);

const useLoadDevices = () => {
  const controllerName = useCurrentController();

  const { data } = useQuery(
    ['metrics', 'controller_devices', controllerName],
    () => fetchDevicesForController(controllerName),
    {
      suspense: true,
    },
  );
  expectDefinedOrThrow(data, new ResourceNotFoundError('Unable to load status of meter devices'));

  return data;
};

const ControllerBadgeAndIcon = () => {
  const data = useLoadDevices();
  const variant = data.connected_status === 'online' ? 'positive' : 'negative';

  return (
    <BandIconAndBadge
      variant={variant}
      size="medium"
      badge={
        <Badge
          arrangement="leading-icon"
          ends="pill"
          icon="controller"
          size="small"
          variant={variant}
        >
          {variant === 'positive' ? 'Online ' : 'Offline'}
        </Badge>
      }
      icon={<HardwareIcon icon="controller" size="medium" />}
    />
  );
};

const APBadgeAndIcon = () => {
  const data = useLoadDevices();
  const sortedAPs = groupBy(data.access_points, (ap) => ap.connected_status);
  const numOnline = sortedAPs.online?.length || 0;
  const numOffline = sortedAPs.offline?.length || 0;
  const variant = numOffline === 0 ? 'positive' : 'negative';

  const badges = (
    <HStack spacing={space(4)}>
      {numOffline > 0 && (
        <Badge
          key="ap-badges-offline"
          arrangement="leading-icon"
          ends="pill"
          icon="accessPoint"
          size="small"
          variant="negative"
        >
          {numOffline}&nbsp;offline
        </Badge>
      )}
      {numOnline > 0 && (
        <Badge
          key="ap-badges-online"
          arrangement="leading-icon"
          ends="pill"
          icon="accessPoint"
          size="small"
          variant="positive"
        >
          {numOnline}/{numOnline + numOffline}
        </Badge>
      )}
    </HStack>
  );

  return (
    <BandIconAndBadge
      variant={variant}
      size="medium"
      badge={badges}
      icon={<HardwareIcon icon="ap" size="medium" />}
    />
  );
};

const ISPQualityBadgeAndIcon = ({ wan }: StatusBarProps) => {
  const controllerName = useCurrentController();

  const { data: ispQualityMetric } = useQuery(
    ['metrics', ISPQuality.series_id, controllerName, wan],
    () => MetricsAPI.fetchMetric(ISPQuality, { controllerName, wan }),
    {
      suspense: true,
    },
  );
  expectDefinedOrThrow(
    ispQualityMetric,
    new MetricsLoadError('Unable to load ISP Quality metrics'),
  );

  let metricValue: number | undefined;
  let metricVariant: BandVariant = 'neutral';
  let icon: IconName = 'information';

  if (ispQualityMetric.length > 0) {
    const points = ispQualityMetric[0].data;
    const latestMetricPoint = points[points.length - 1];
    metricValue = latestMetricPoint.value;
    metricVariant = metricValue > 0.5 ? 'positive' : 'negative';
    icon = metricValue > 0.5 ? 'checkmark' : 'cross';
  }

  return (
    <BandIconAndBadge
      variant={metricVariant}
      size="medium"
      badge={
        <Badge
          arrangement="leading-icon"
          ends="pill"
          icon={icon}
          size="small"
          variant={metricVariant}
        >
          {metricValue !== undefined ? d3.format('.2~%')(metricValue) : 'N/A'}
        </Badge>
      }
      icon={<NetworkIcon />}
    />
  );
};

type StatusBarProps = {
  wan: string;
};

export default function StatusBar({ wan }: StatusBarProps) {
  return (
    <Suspense fallback={<LoadingGrid />}>
      <Grid>
        <GridItem>
          <ControllerBadgeAndIcon />
          <Small>Controller</Small>
        </GridItem>
        <GridItem>
          <APBadgeAndIcon />
          <Small>Access points</Small>
        </GridItem>
        <GridItem>
          <ISPQualityBadgeAndIcon wan={wan} />
          <Small>ISP quality</Small>
        </GridItem>
      </Grid>
    </Suspense>
  );
}
