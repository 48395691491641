import type { UserTokenResponse } from '@meterup/proto/esm/api';
import { getMany, getOne, isDefined, makeAPICall, mutateVoid } from '@meterup/common';
import { api } from '@meterup/proto';
import axios from 'axios';
import { orderBy } from 'lodash';

import type { MeterV2WirelessTagServiceSet } from './config';
import type {
  ClientData,
  ControllerStateResponse,
  DeviceData,
  DeviceDataAndRadios,
  DevicesData,
  LegacyControllerMessageData,
  LegacyNetworkInfoData,
  ListClientsData,
  LocationData,
  LocationsData,
  NetworkPlansData,
  OnboardingData,
  ProvidersData,
  SSIDData,
  StatusData,
  SuggestedPasswordData,
  UserData,
  UsersData,
  UsersTokensResponseJSON,
  UserTokenResponseJSON,
} from './types';
import { isWireless } from '../utils/clientLists';
import { delay } from '../utils/delay';
import { retry } from '../utils/retry';
import { axiosInstanceJSON } from './axiosInstanceJSON';
import FAKE_NETWORK_INFO_RESPONSE from './data/network_info';

const API_BASE_URL = import.meta.env.REACT_APP_API_URL;

export const getIdentity = async () =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<api.IdentityResponse>('/v1/identity');
    return result.data;
  });

export const startNetworkInfoRequest = async (
  networkName: string,
): Promise<LegacyControllerMessageData | null> =>
  getOne(async () => {
    if (import.meta.env.REALM === 'local') {
      return { links: { self: '/' } } as any;
    }

    const response = await axiosInstanceJSON.get<LegacyControllerMessageData>(
      `/v1/controllers/${networkName}/network-info?timeout=20s`,
    );

    return response.data;
  });

const fetchFromLegacyControllerLink = async <T extends any>(link: string): Promise<T> =>
  makeAPICall(async () => {
    const response =
      import.meta.env.REALM === 'local'
        ? await delay(80).then(() => FAKE_NETWORK_INFO_RESPONSE)
        : await axiosInstanceJSON.get<LegacyControllerMessageData>(link, { timeout: 800 });
    return JSON.parse(response.data?.response?.data!);
  });

export const fetchNetworkInfo = async (controllerName: string): Promise<LegacyNetworkInfoData> =>
  makeAPICall(async () => {
    const linkResponse = await startNetworkInfoRequest(controllerName);
    const link = linkResponse?.links?.self;
    if (link) {
      return retry(() => fetchFromLegacyControllerLink<LegacyNetworkInfoData>(link), 7);
    }
    throw new Error('Failed to read network info');
  });

export const fetchNetworkISPInfo = async (company: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<NetworkPlansData>(
      `${API_BASE_URL}/api-proxy/v1/dashboard/controllers/${company}/internet-service-plans`,
    );
    return result.data.plans;
  });

export const fetchControllers = async (company: string): Promise<api.ControllerResponse[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.CompanyControllersResponse>(
      `/v1/companies/${company}/controllers`,
    );

    return result.data.controllers;
  });

export const fetchInstalledPrimaryControllers = async (company: string) => {
  const controllers = await fetchControllers(company);
  return controllers.filter(
    (controller) =>
      controller.lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY,
  );
};

export const fetchStatus = async (controller: string): Promise<StatusData | null> =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<StatusData>(
      `/v1/dashboard/controllers/${controller}/status`,
    );
    return result.data;
  });

export const fetchControllerConfig = async (controller: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<{
      config: object;
      last_updated_at?: string;
    }>(`/v1/controllers/${controller}/config`);
    return result.data;
  });

export const upsertControllerConfigKey = async (
  controllerName: string,
  key: string,
  value: object,
) =>
  mutateVoid(async () =>
    axiosInstanceJSON.post(`/v1/controllers/${controllerName}/config/${key}`, {
      config: value,
    }),
  );

export const upsertMACAddressAlias = async (
  companySlug: string,
  macAddress: string,
  alias: string,
) =>
  mutateVoid(async () =>
    axiosInstanceJSON.post(`/v1/companies/${companySlug}/mac-address-aliases`, {
      mac: macAddress,
      alias,
    }),
  );

export const fetchClients = async (controller: string): Promise<api.UserClient[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.ClientListDashboardResponse>(
      `/v1/dashboard/controllers/${controller}/clients`,
    );

    return orderBy(result.data.clients, ['last_seen', isWireless], ['desc', 'desc']).filter(
      (client) => !client.ip_address.startsWith('10.102'),
    );
  });

export const fetchDevicesWithRadioData = async (
  controller: string,
): Promise<DeviceDataAndRadios[]> =>
  getMany(async () => {
    const devicesData = await axiosInstanceJSON.get<DevicesData>(
      `/v1/dashboard/controllers/${controller}/devices`,
    );

    const { devices } = devicesData.data;
    const apsAndRadios = devicesData.data.access_points_radios;
    const combined = devices.map((device) => ({
      device: device as DeviceData,
      apAndRadios: apsAndRadios.find((r) => r.access_point?.name === device.name) ?? null,
    }));

    return combined.sort((a, b) => {
      if (a.device.physical_location === 'Wired') {
        return 1;
      }

      if (a.device.clients !== b.device.clients) {
        return a.device.clients > b.device.clients ? -1 : 1;
      }

      return a.device.physical_location.localeCompare(b.device.physical_location);
    });
  });

export const fetchDeviceWithRadioData = async (
  controller: string,
  deviceName: string,
): Promise<DeviceDataAndRadios | null> =>
  getOne(async () => {
    const devices = await fetchDevicesWithRadioData(controller);
    return devices.find((d) => d.device.name === deviceName) ?? null;
  });

export const fetchDevicesForController = async (
  controller: string,
): Promise<api.ControllerDeviceResponse | null> =>
  getOne(async () => {
    const results = await axiosInstanceJSON.get<api.ControllerDeviceResponse>(
      `/v1/controllers/${controller}/devices`,
    );
    return results.data;
  });

export const fetchDevices = async (controller: string): Promise<DeviceData[]> =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<DevicesData>(
      `/v1/dashboard/controllers/${controller}/devices`,
    );

    const { devices } = response.data;
    return orderBy(devices as DeviceData[], (d) => d.clients, 'desc');
  });

export const fetchDevice = async (
  controller: string,
  deviceName: string,
): Promise<DeviceData | null> =>
  getOne(async () => {
    const devices = await fetchDevices(controller);
    return devices.find((device) => device.name === deviceName) ?? null;
  });

export const fetchDeviceClients = async (
  device: string,
  controller: string,
): Promise<ClientData[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<ListClientsData>(
      `/v1/dashboard/controllers/${controller}/clients?ap=${device}`,
    );

    return result.data.clients;
  });

export const fetchUserClients = async (userSid: string) =>
  getMany(async () => {
    const results = await axiosInstanceJSON.get<api.ConnectedClientsResponse>(
      `/v1/dashboard/company-users/${userSid}/clients`,
    );
    return results.data.clients;
  });

export const fetchClientConnectionHistory = async (
  controller: string,
  mac: string,
): Promise<ClientData[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<ListClientsData>(
      `/v1/dashboard/controllers/${controller}/clients?mac=${mac}`,
    );

    return orderBy(result.data.clients, (d) => d.last_seen, 'desc');
  });

export const fetchDeviceConnectionHistory = async (
  controller: string,
  deviceName: string,
): Promise<ClientData[]> =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<ListClientsData>(
      `/v1/dashboard/controllers/${controller}/clients?ap=${deviceName}`,
    );

    return result.data.clients;
  });

export const fetchFloorPlan = async (controller: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get(`/v1/controllers/${controller}/floor-plan`, {
      responseType: 'blob',
    });

    return URL.createObjectURL(result.data);
  });

export const fetchProviders = async (): Promise<api.Provider[]> =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<ProvidersData>('/v1/providers');
    return response.data.providers;
  });

export const getSuggestedPassword = async (wordCount: number) =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<SuggestedPasswordData>('/v1/password-suggestions', {
      params: {
        'word-count': wordCount,
      },
    });
    return result.data.suggested_password;
  });

export const fetchCompanyLocations = async (companySlug: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<LocationsData>(
      `/v1/companies/${companySlug}/locations`,
    );

    return result.data.locations.map((wrapper) => wrapper.location);
  });

export const fetchCompany = async (companySlug: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.CompanyResponse>(`/v1/companies/${companySlug}`);

    return result.data;
  });

export const fetchCompanyLocation = async (
  companySlug: string,
  sublocationSid: string,
): Promise<LocationData | null> =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<LocationsData>(
      `/v1/companies/${companySlug}/locations`,
    );

    return (
      result.data.locations
        .map((wrapper) => wrapper.location)
        .find((location) => location.sublocation_sid === sublocationSid) ?? null
    );
  });

export const fetchOnboarding = async (companySlug: string, sublocationSid: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<OnboardingData>('/v1/onboardings', {
      params: {
        'company-slug': companySlug,
        'sublocation-sid': sublocationSid,
      },
    });

    return result.data;
  });

export const updateOnboarding = async (data: Partial<OnboardingData>) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.put<OnboardingData>('/v1/onboardings', data);
    return result.data;
  });

export const createUser = async (
  companySlug: string,
  email: string,
  companyRole: api.CompanyMembershipRole,
) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.post<UserData>(`/v1/companies/${companySlug}/users`, {
      email,
      company_role: companyRole,
    });
    return result.data;
  });

export const getCompanyUsers = async (companySlug: string) =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<UsersData>(`/v1/companies/${companySlug}/users`);

    return response.data.users;
  });

export const getUserTokens = async (companyUserSid: string): Promise<UserTokenResponse[]> =>
  getMany(async () => {
    const response = await axiosInstanceJSON.get<UsersTokensResponseJSON>(
      `/v1/company-users/${companyUserSid}/tokens`,
    );

    return response.data.tokens;
  });

export const getToken = async (
  userSid: string,
  tokenSid: string,
): Promise<UserTokenResponseJSON | null> =>
  getOne(async () => {
    const tokens = await getUserTokens(userSid);

    return tokens.find((token) => token.sid === tokenSid) ?? null;
  });

export const createToken = async (
  params: api.UserTokenCreateRequest,
): Promise<api.UserTokenResponse | null> =>
  getOne(async () => {
    const response = await axiosInstanceJSON.post<UserTokenResponseJSON>(
      `/v1/company-users/${params.user_sid}/tokens`,
      params,
    );

    return response.data;
  });

export const getOrCreateSingleToken = async (
  userSid: string,
): Promise<UserTokenResponseJSON | null> =>
  makeAPICall(async () => {
    const tokens = await getUserTokens(userSid);

    if (tokens.length) {
      return tokens[0];
    }

    return createToken({
      alias: 'Default token created in Dashboard',
      max_clients_limit: 10,
      user_sid: userSid,
    });
  });

export const deleteToken = async (userSid: string, tokenSid: string): Promise<void> =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete(`/v1/company-users/${userSid}/tokens/${tokenSid}`);
  });

export const getUser = async (companySlug: string, userSid: string): Promise<UserData | null> =>
  getOne(async () => {
    const response = await axiosInstanceJSON.get<UserData>(
      `/v1/companies/${companySlug}/users/${userSid}`,
    );

    return response.data;
  });

export const logoutUser = async (): Promise<void> =>
  mutateVoid(async () => {
    await axiosInstanceJSON.post(`/v1/logout`);
  });

export const deleteUser = async (companySlug: string, userSid: string): Promise<void> =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete(`/v1/companies/${companySlug}/users/${userSid}`);
  });

export interface TokenAndUser extends UserTokenResponseJSON {
  user: UserData;
}

export const getAllTokenAndUsers = async (companySlug: string): Promise<TokenAndUser[]> => {
  const companyUsers = await getCompanyUsers(companySlug);
  if (isDefined(companyUsers)) {
    return (
      await axios.all(
        companyUsers.map(async (user) => {
          const tokens = await getUserTokens(user.sid);
          return tokens.map((token) => ({
            ...token,
            user,
          }));
        }),
      )
    ).flat();
  }
  return [];
};

const KNOWN_STANDALONE_TOKEN_ALIAS = 'Default standalone token';

export const getOrCreateStandaloneToken = async (
  companySlug: string,
  userSid: string,
): Promise<UserTokenResponseJSON | null> =>
  getOne(async () => {
    const tokenAndUsers = await getAllTokenAndUsers(companySlug);
    const foundToken = tokenAndUsers.find((token) => token.alias === KNOWN_STANDALONE_TOKEN_ALIAS);

    if (isDefined(foundToken)) {
      return foundToken;
    }

    return createToken({
      alias: KNOWN_STANDALONE_TOKEN_ALIAS,
      max_clients_limit: 100,
      user_sid: userSid,
    });
  });

const cosServiceSetToSSIDData = (
  response: LegacyNetworkInfoData,
  ssid: string,
  json: MeterV2WirelessTagServiceSet,
): SSIDData => {
  const pskFromStateJSON = response.psk_rotation_state_json?.service_sets[ssid]?.PSK;
  const pskFromLegacyData = ssid === response.guest_ssid ? response.guest_password ?? '' : '';
  return {
    ssid,
    type: json.bands?.join(', ') ?? 'Unknown',
    sid: ssid,
    password: json['psk-rotation']
      ? {
          type: 'rotating',
          value: pskFromStateJSON ?? pskFromLegacyData,
          rotation_interval_name: json['psk-rotation']?.frequency,
        }
      : { type: 'static', value: json.psk ?? '' },
  };
};

// This function reshapes the network info data into a list of SSIDs. No
// endpoint exists that would return this data yet.
export async function fetchControllerSSIDs(controller: string): Promise<SSIDData[]> {
  const networkInfoData = await fetchNetworkInfo(controller);

  if (isDefined(networkInfoData)) {
    if (networkInfoData.config_json) {
      return Object.entries(networkInfoData.config_json['service-sets'] ?? {}).map(([ssid, json]) =>
        cosServiceSetToSSIDData(networkInfoData, ssid, json),
      );
    }

    return [
      {
        sid: 'private',
        ssid: networkInfoData.private_ssid,
        password: { type: 'static', value: networkInfoData.private_password } as const,
        type: '5 GHz',
      },
      networkInfoData.private_2g_ssid
        ? {
            sid: 'private_2g',
            ssid: networkInfoData.private_2g_ssid,
            password: { type: 'static', value: networkInfoData.private_password } as const,
            type: '2.4 GHz',
          }
        : null,
      networkInfoData.guest_ssid
        ? {
            sid: 'guest',
            ssid: networkInfoData.guest_ssid,
            password: networkInfoData.guest_password
              ? ({
                  type: 'rotating',
                  value: networkInfoData.guest_password,
                  rotation_interval_name: networkInfoData.guest_strategy,
                } as const)
              : null,
            type: 'Guest',
          }
        : null,
    ].filter(isDefined);
  }

  return [];
}

export async function fetchControllerSSID(
  controller: string,
  sid: string,
): Promise<SSIDData | null> {
  const networkInfoData = await fetchControllerSSIDs(controller);
  return networkInfoData.find((ssid) => ssid.sid === sid) ?? null;
}

export const fetchControllerEvents = async (controllerName: string) =>
  getMany(async () => {
    const currentDate = new Date();
    const fourWeeksAgo = currentDate.setDate(currentDate.getDate() - 28);
    const fourWeeksAgoUnix = Math.round(new Date(fourWeeksAgo).getTime() / 1000).toString();
    const result = await axiosInstanceJSON.get<api.AdhocControllerEventListResponse>(
      `/v1/controllers/${controllerName}/events?minimum-created-at=${fourWeeksAgoUnix}`,
    );
    return orderBy(result.data.events, (e) => e.created_at, 'desc');
  });

export const listEnabledContentFiltersForCompany = async (companySlug: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.CompanyContentFiltersResponse>(
      `/v1/companies/${companySlug}/content-filters`,
    );
    return result.data;
  });

export const enableContentFilterForController = async (controllerName: string) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.post<api.ContentFilterLocation>(
      `/v1/controllers/${controllerName}/content-filters`,
    );
  });

export const getRulesForCompany = async (companySlug: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.ContentFilterRules>(
      `/v1/companies/${companySlug}/content-filters/rules`,
    );
    return result.data.rules;
  });

export const getRuleForCompany = async (companySlug: string, id: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<api.ContentFilterRule>(
      `/v1/companies/${companySlug}/content-filters/rules/${id}`,
    );
    return result.data;
  });

export const getRuleCategories = async (companySlug: string) =>
  getMany(async () => {
    const result = await axiosInstanceJSON.get<api.ContentFilterRuleCategories>(
      `/v1/companies/${companySlug}/content-filters/rule-categories`,
    );
    return result.data.categories;
  });

export const createRuleForCompany = async (
  companySlug: string,
  data: Partial<api.UpsertContentFilterRuleRequest>,
) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.post<api.ContentFilterRule>(
      `/v1/companies/${companySlug}/content-filters/rules`,
      data,
    );
    return result.data;
  });

export const updateRuleForCompany = async (
  companySlug: string,
  id: string,
  data: Partial<api.UpsertContentFilterRuleRequest>,
) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.put<api.ContentFilterRule>(
      `/v1/companies/${companySlug}/content-filters/rules/${id}`,
      data,
    );
  });

export const deleteRuleForCompany = async (companySlug: string, id: string) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete<void>(
      `/v1/companies/${companySlug}/content-filters/rules/${id}`,
    );
  });

export const fetchControllerState = async (controllerName: string) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.get<ControllerStateResponse>(
      `/v1/controllers/${controllerName}/state`,
    );
    return result.data;
  });
